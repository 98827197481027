import { Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "./App.css";
import NoMatch from "./components/404page/NoMatch";
import English from "./components/english/english";
import Finish from "./components/finishpage/finish.page";
import FinishPageEnglish from "./components/finishpageenglish/finish.page.english";
import FinishPageRussian from "./components/finishpagerussian/finish.page.russian";
import Main from "./components/main/main";
import Russian from "./components/russian/russian";
import Uzbek from "./components/uzbek/uzbek";

function App() {
  return (
    <div className="App">
      <ToastContainer />
      <Switch>
        <Route exact path="/" component={() => <Main />} />
        <Route path="/russian" component={() => <Russian />} />
        <Route path="/english" component={() => <English />} />
        <Route path="/uzbek" component={() => <Uzbek />} />
        <Route path="/finishpage" component={() => <Finish />} />
        <Route
          path="/finishpage_russian"
          component={() => <FinishPageRussian />}
        />
        <Route
          path="/finishpage_english"
          component={() => <FinishPageEnglish />}
        />
        <Route path="*">
          <NoMatch />
        </Route>
      </Switch>
    </div>
  );
}

export default App;
