import dayjs from "dayjs";
import { create } from "zustand";

const today = dayjs();
const thisYears = today.get("year");
const nextYears = today.add(1, "year");
const nextYearFormat = dayjs(nextYears).format("YYYY");

export const useStore = create((set) => ({
  thisYear: thisYears,
  // setThisYear: (date) => set({ thisYear: date }),
  nextYear: nextYearFormat,
}));
